import React from "react"
import { useStaticQuery, Link } from "gatsby"
import "./style.scss"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div id="site-container">
      <Helmet>
        <title>Justin Sharkey</title>
        <script
          data-ad-client="ca-pub-5026522069257618"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        ></script>
      </Helmet>
      <div id="site-header">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <div class="container">
            <a class="navbar-brand" href="/">
              {data.site.siteMetadata.title}
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-controls="navbar-collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse">
              <div class="navbar-nav">
                <a class="nav-item nav-link" href="/">
                  Home
                </a>
                <a class="nav-item nav-link" href="/resume/">
                  Resume
                </a>
                <a class="nav-item nav-link" href="/contact/">
                  Contact
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div id="site-body">
        <div class="container">{children}</div>
      </div>
      <div id="site-footer">
        <footer class="footer">
          <div class="container">
            <div class="text-muted text-center">©2020 Justin Sharkey</div>
          </div>
        </footer>
      </div>
    </div>
  )
}
